var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-w-full tw-h-[52rem] tw-flex tw-justify-center tw-items-center tw-border tw-border-red"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-p-20 tw-bg-black tw-rounded-2xl tw-space-y-10 tw-items-center"},[_vm._m(0),_c('div',{staticClass:"tw-w-full tw-max-w-md tw-mx-auto"},[_c('div',{staticClass:"tw-flex tw-justify-center tw-space-x-2 tw-border-b tw-border-gray-200"},[_c('button',{class:[
                        _vm.activeTab === 'register'
                            ? 'tw-border-rose-950 tw-bg-rose-950 tw-rounded-2xl tw-text-white tw-hover:bg-rose-950'
                            : 'tw-border-transparent tw-text-gray-500',
                        'tw-px-4 tw-py-2 tw-border-b-2 tw-font-bold tw-text-lg tw-transition-colors'
                    ],on:{"click":function($event){_vm.activeTab = 'register'}}},[_vm._v(" Üye Ol ")]),_c('button',{class:[
                        _vm.activeTab === 'login'
                            ? 'tw-border-rose-950 tw-bg-rose-950 tw-rounded-2xl tw-text-white tw-hover:bg-rose-950'
                            : 'tw-border-transparent tw-text-gray-500',
                        'tw-px-4 tw-py-2 tw-border-b-2 tw-font-bold tw-text-lg tw-transition-colors'
                    ],on:{"click":function($event){_vm.activeTab = 'login'}}},[_vm._v(" Giriş Yap ")])]),_c('div',{staticClass:"tw-mt-4"},[(_vm.activeTab === 'register')?_c('div',[_c('RegisterView')],1):_vm._e(),(_vm.activeTab === 'login')?_c('div',[_c('LoginView')],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tw-flex tw-flex-col tw-p-2 tw-bg-rose-950 tw-rounded-2xl tw-space-y-3"},[_c('img',{attrs:{"width":"50px","src":require("@/assets/YENILOGO.svg"),"alt":"Logo"}})])
}]

export { render, staticRenderFns }